import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Dropdown, Menu, MenuProps, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import useAuth from "@/hooks/useAuth";
import { saveCategories } from "@/helpers";
import Loader from "@/components/UI/Loader";

const { Text } = Typography;

export default function Layout() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  function handleHome() {
    navigate("/");
  }

  function handleLogout() {
    auth.signout(() => {
      navigate("/login");
    });
  }

  function handleSelect(data: any) {
    const { key } = data;
    navigate(key);
  }

  function getSelectedKeys(path:string) {
    switch (path) {
      case "/camera":
      case "/category":
      case "/user":
        return [path]
      default:
        return ["/"]
    }
  }

  useEffect(() => {
    auth.checkAuth().then((result) => {
      if (result) {
        saveCategories();
      }
    });
    // eslint-disable-next-line
  }, []);

  const items: MenuProps["items"] = [
    {
      label: (
        <Text>Выйти</Text>
      ),
      onClick: handleLogout,
      key: "1"
    }
  ];

  const menuItems = [
    {
      key: "/",
      label: "Заявки"
    },
    {
      key: "/category",
      label: "Категории"
    },
    {
      key: "/camera",
      label: "Камеры"
    },
    {
      key: "/user",
      label: "Пользователи"
    }
  ];

  return (
    <>
      {
        auth.isAuth &&
        !auth.isLoading &&
        <header className="header">
          <div className="header__left">
            <Button type="text"
                    style={{
                      fontSize: 16,
                      lineHeight: "24px",
                      color: "white",
                      padding: 0,
                      marginRight: 16
                    }}
                    onClick={handleHome}>
              ПД: Диспетчер
            </Button>
            {
              auth.isAuth &&
              <Menu
                selectedKeys={getSelectedKeys(location.pathname)}
                mode="horizontal"
                theme="dark"
                onSelect={handleSelect}
                items={menuItems}
                style={{
                  width: "50%"
                }}
              />
            }
          </div>

          <Dropdown menu={{ items }} trigger={["click", "hover"]}>
            <div className="header__right">
              <Avatar icon={<UserOutlined />} />
              <Text style={{
                color: "white",
                marginLeft: 12
              }}>
                {auth.user}
              </Text>
            </div>
          </Dropdown>

        </header>
      }
      <main>
        {auth.isLoading && <Loader />}
        {!auth.isLoading && <Outlet />}
      </main>
    </>
  );
}
